const options = [
  {
    key: 'master-data',
    label: 'Master Data',
    leftIcon: 'ion-ios-folder-outline',
    role: ['admin', 'ultraman'],
    children: [
      {
        key: 'member',
        label: 'Member',
        role: ['admin', 'ultraman']
      },
      {
        key: 'voucher',
        label: 'Voucher',
        role: ['admin', 'ultraman']
      },
      {
        key: 'promo-slide',
        label: 'Promo Slide',
        role: ['admin', 'ultraman']
      },
      {
        key: 'admin',
        label: 'Admin',
        role: ['admin', 'ultraman']
      }
    ]
  },
  {
    key: 'sync-product',
    label: 'Sync Product Prices',
    leftIcon: 'ion-android-sync',
    role: ['admin', 'ultraman']
  },
  {
    key: 'order-bengkel',
    label: 'Bengkel Order',
    leftIcon: 'ion-hammer',
    role: ['admin', 'ultraman', 'bengkel']
  },
  {
    key: 'ecommerce',
    label: 'Ecommerce',
    leftIcon: 'ion-social-dropbox-outline',
    role: ['admin', 'ultraman'],
    children: [
      {
        key: 'category',
        label: 'Kategori',
        role: ['admin', 'ultraman']
      },
      {
        key: 'product',
        label: 'Produk',
        role: ['admin', 'ultraman']
      },
      {
        key: 'transaction',
        label: 'Transaksi',
        role: ['admin', 'ultraman']
      },
      {
        key: 'mutasi-saldo',
        label: 'Mutasi Saldo Shipping',
        role: ['admin', 'ultraman']
      }
    ]
  }
];

export default options;
