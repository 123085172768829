import actions from './actions';

const initState = { accessToken: null, refreshToken: null, profile: null };

export default function authReducer(state = initState, action) {
  switch (action.type) {
    case actions.LOGIN_SUCCESS:
      return {
        accessToken: action.token,
        refreshToken: action.refreshToken,
        profile: action.profile
      };
    case actions.LOGOUT:
      return initState;
    default:
      return state;
  }
}
