export const PUBLIC_ROUTE = {
  LANDING: '/',
  SIGN_IN: '/login',
  RESET_PIN_HOME: '/resetpin-success',
  RESET_PIN: '/resetpin/:id',
  PRIVACY_POLICY: '/privacy-policy',
  CONTACT_US: '/contact-us',
  SUCCESS_PAGE: '/contact-us/success',
  RESET_PASSWORD_HOME: '/resetpassword-success',
  RESET_PASSWORD: '/resetpassword/:id'
};
