// import JSEncrypt from 'jsencrypt';
import crypto from 'crypto';

const { REACT_APP_PUBLIC_KEY: publicKey } = process.env;

// const encrypted = (password) => {
//   try {
//     const encrypt = new JSEncrypt();
//     encrypt.setPublicKey(publicKey);
//     const encryptKey = encrypt.encrypt(password);
//     return encryptKey;
//   } catch (err) {
//     console.log('error : ', err);
//     return;
//   }
// };

const encryptedData = (stringEnc) =>
  crypto
    .publicEncrypt(
      {
        key: publicKey,
        padding: crypto.constants.RSA_PKCS1_OAEP_PADDING,
        oaepHash: 'sha256'
      },
      Buffer.from(stringEnc)
    )
    .toString('base64');

export { encryptedData };
