import React from 'react';
import { ConfigProvider } from 'antd';
import { useSelector } from 'react-redux';
import { ThemeProvider } from 'styled-components';
import themes from '@iso/config/theme/theme.config';

export default function AppProvider({ children }) {
  const { themeName } = useSelector((state) => state.ThemeSwitcher.changeThemes);
  return (
    <ConfigProvider>
      <ThemeProvider theme={themes[themeName]}>{children}</ThemeProvider>
    </ConfigProvider>
  );
}
