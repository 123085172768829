import axios from 'axios';
import { encryptedData } from '@iso/lib/helpers/encrypted';

const { REACT_APP_API_URL } = process.env;

const instance = axios.create({ baseURL: REACT_APP_API_URL });

instance.interceptors.request.use(
  (req) => {
    return req;
  },
  (error) => {
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  (response) => response.data,
  ({ response }) => {
    return Promise.reject(response.data.message);
  }
);

const publicApi = {
  login: (data) => {
    const postData = {
      ...data,
      password: encryptedData(data.password)
    };
    return instance({
      method: 'post',
      url: '/auth/loginadmin',
      data: postData
    });
  },
  contactUs: (data) => {
    return instance({
      method: 'post',
      url: '/contact_us',
      data: data
    });
  }
};

export default publicApi;
